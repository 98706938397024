import React from 'react';
import * as styles from './carbon-wood.module.scss';
import Layout from "../../page-components/layout/layout";

import LandingPageHeaderSrc from "./LandingPageHeader.jpg";
import LandingBild1 from "./LandingBild1.png";
import LandingBild2 from "./LandingBild2.png";
import ReactPlayer from "react-player";

import ProduktStealthDriver from "./ProdukStealthDriver.png";
import ProduktStealthPlusDrive from "./ProduktStealthFWPlus.png";
import ProduktStealthHDDriver from "./ProdukStealthHDDriver.png";

import ProduktStealthFairway from "./ProduktStealthFW.png";
import ProduktStealthPlusFairway from "./ProduktStealthFWPlus.png";
import ProduktStealthHbyrid from "./ProduktStealthHY.png"

import Damen from "./ProduktStealthDriverDamen.png";
import DamenFairway from "./ProduktStealthFWDamen.png";
import DamenHybrid from "./ProduktStealthHYDamen.png";

import GsButton from "../../page-components/buttons/gsButton";

const CarbonWoodPage =() => {

    return <Layout>

        <div className={"container bg-white h-100 pb-5 " + styles.landingpage}>
            <div className="row">
                <div className="col-12 ">
                    <img src={LandingPageHeaderSrc} className={styles.mainTeaser} />
                </div>
            </div>

            <div className="row mt-0 align-items-center">
                <div className="col-lg-6">
                    <p>
                        Nach über 20 Jahren beendet TaylorMade die Ära der klassischen Titan-Schlagfläche. Das neu entwickelte 60x Carbon Twist Face ist 44 % leichter als eine vergleichbare Schlagfläche aus Titan sowie 11 % größer als TaylorMade SIM2 und SIM2 Max Driver und 20 % größer als SIM und SIM Max Driver.
                    </p>
                    <br />
                    <p>
                        60 Schichten Carbonfasern wurden strategisch perfekt angeordnet und sorgen für eine enorme Kraftübertragung auf der gesamten Schlagfläche des Schlägerkopfes. Die daraus resultierende Ballgeschwindigkeit sorgt für mehr Distanz und Fehlerverzeihung.
                    </p>
                </div>

                <div className="col-lg-6">
                    <img src={LandingBild1} className={"img-fluid w-100"} />
                </div>

            </div>

            <div className="row mt-0 align-items-center">
                <div className="col-lg-6">
                    <img src={LandingBild2} className={"img-fluid w-100"} />
                </div>

                <div className="col-lg-6">
                    <p>
                    Ebenfalls revolutionär ist die neue Nanotextur-
                    Technologie. Das 60x Carbon Twist Face wird von
                    einer Polyurethanhülle umschlossen mit einer
                    nanotexturierten Beschichtung um Abflugwinkel
                    und Spin zu optimieren und somit mehr Länge zu
                    gewinnen.
                    </p>

                    <br />

                    <p>
                        Durch die Gewichtseinsparungen des 60x Carbon
                        Twist Face konnte mehr Gewicht tief im
                        Schlägerkopf positioniert werden, was im Vergleich
                        zum Stealth Plus+ zu 15 % mehr MOI führt
                    </p>

                </div>
            </div>


            <div className={"row mt-5 align-items-center " + styles.products}>

                <div className="col-12 mb-3 text-center">
                    <h2>Taylormade Driver</h2>
                </div>

                <div className="col-md-4">
                    <b>Stealth Driver</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-driver"}>
                    <img src={ProduktStealthDriver} className={"img-fluid w-100"} />

                    <GsButton className={"mt-3"} variant={"primary"}>Zum Produkt</GsButton>
                    </a>

                </div>

                <div className="col-md-4">
                    <b>Stealth Plus Driver</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-plus-driver"}>
                    <img src={ProduktStealthPlusDrive} className={"img-fluid w-100"} />

                    <GsButton variant={"primary"} className={"mt-3"}>Zum Produkt</GsButton>
                    </a>
                </div>

                <div className="col-md-4">
                    <b>Stealth HD Driver</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-hd-driver"}>
                    <img src={ProduktStealthHDDriver}className={"img-fluid w-100"} />

                    <GsButton variant={"primary"} className={"mt-3"}>Zum Produkt</GsButton>
                    </a>
                </div>


            </div>



            <div className="row mt-5 align-items-center">

                <div className="col-lg-6">
                    <div className={styles.playerWrapper}>
                        <ReactPlayer  className={styles.reactPlayer}      width='100%' height='100%' url={"https://www.youtube.com/watch?v=Fvz-sxRQrxE"} />
                    </div>

                </div>

                <div className="col-lg-6">
                    <p>
                    Der Trägheitsgenerator ist nach wie vor die
                    Grundlage für die verbesserten aerodynamischen
                    Eigenschaften. Das Ergebnis ist eine schlüpfrig-
                    schnelle Kopfform, die für Golfer aller Spielstärken
                    die Schwunggeschwindigkeit beim Abschwung
                    unterstützt.
                    </p>
                    <br />
                    <p>
                    Unser flexibelstes Speed Pocket™ Design wurde
                    entwickelt, um die Ballgeschwindigkeit zu
                    maximieren und zusätzliche Fehlerverzeihung bei
                    weit unten auf der Schlagfläche getroffenen
                    Schlägen zu bieten.
                    </p>
                </div>
            </div>


            <div className={"row mt-5 align-items-center " + styles.products}>

                <div className="col-12 mb-3 text-center">
                    <h2>Taylormade Fairwayhözer + Hybriden</h2>
                </div>

                <div className="col-md-4">
                    <b>Stealth Fairwayholz</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-fairwayholz"}>
                        <img src={ProduktStealthFairway} className={"img-fluid w-100"} />

                        <GsButton className={"mt-3"} variant={"primary"}>Zum Produkt</GsButton>
                    </a>

                </div>

                <div className="col-md-4">
                    <b>Stealth Plus Fairwayholz</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-plus-fairwayholz"}>
                        <img src={ProduktStealthPlusFairway} className={"img-fluid w-100"} />

                        <GsButton className={"mt-3"} variant={"primary"}>Zum Produkt</GsButton>
                    </a>

                </div>

                <div className="col-md-4">
                    <b>Stealth Hybrid</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-hybrid"}>
                        <img src={ProduktStealthHbyrid} className={"img-fluid w-100"} />

                        <GsButton className={"mt-3"} variant={"primary"}>Zum Produkt</GsButton>
                    </a>

                </div>

            </div>



            <div className={"row mt-5 pt-lg-5 align-items-center " + styles.products}>

                <div className="col-12 mb-3 text-center">
                    <h2>Taylormade Damen Hölzer</h2>
                </div>

                <div className="col-md-4">
                    <b>Stealth Damen Driver</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-hd-damen-driver"}>
                        <img src={Damen} className={"img-fluid w-100"} />

                        <GsButton className={"mt-3"} variant={"primary"}>Zum Produkt</GsButton>
                    </a>

                </div>


                <div className="col-md-4">
                    <b>Stealth Damen Fairwayholz</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-damen-fairwayholz"}>
                        <img src={DamenFairway} className={"img-fluid w-100"} />

                        <GsButton className={"mt-3"} variant={"primary"}>Zum Produkt</GsButton>
                    </a>

                </div>


                <div className="col-md-4">
                    <b>Stealth Damen Hybrid</b>

                    <a href={"https://www.golfsport.at/produkt/taylormade-stealth-damen-hybrid"}>
                        <img src={DamenHybrid} className={"img-fluid w-100"} />

                        <GsButton className={"mt-3"} variant={"primary"}>Zum Produkt</GsButton>
                    </a>

                </div>

            </div>

            <div className="row mt-5 pt-lg-5">
                <div className="col-lg-6 col-12">

                    <div className={styles.playerWrapper}>
                        <ReactPlayer  className={styles.reactPlayer}      width='100%' height='100%' url={"https://www.youtube.com/watch?v=r5ZZAms14AE"} />
                    </div>

                </div>
            </div>


        </div>

    </Layout>

};

export default CarbonWoodPage;
